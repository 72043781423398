import { Component } from '@angular/core';
import * as dayjs from 'dayjs';
import { OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  currentYear: number;
  currentTime: string;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    const _self = this;
    async function showTime() {
      _self.currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      setTimeout(showTime, 1000);
    }
    showTime();
  }


}
