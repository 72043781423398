import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule) },
  { path: 'brick', loadChildren: () => import('./brick/brick.module').then(mod => mod.BrickModule) },
  { path: 'brick/:brickCode', loadChildren: () => import('./brick/brick.module').then(mod => mod.BrickModule) },
  { path: 'trend', loadChildren: () => import('./trend/trend.module').then(mod => mod.TrendModule) },
  { path: 'trend/:brickCode', loadChildren: () => import('./trend/trend.module').then(mod => mod.TrendModule) },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/error' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
