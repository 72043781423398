<header class="header">
    <div class="noticebox">
        <div class="noticeboxc wrap">
            <div class="noticeboxl float-fl ">
                <div class="noticon">最新公告：</div>
                <div class="newscontent">
                    <marquee behavior="scroll" direction="left" scrollamount="6" onmouseover="this.stop()"
                        onmouseout="this.start()">
                        <span class="textspace">
                            新188最新开奖网:
                            <a class="site-link" href="https://www.188xin.com">https://www.188xin.com</a>
                            <a class="site-link" href="https://www.188xin.cc">https://www.188xin.cc</a>
                            <a class="site-link" href="https://www.188xin.net">https://www.188xin.net</a>
                            最全面的官网开奖网数据，公平，公正，公开！
                        </span>
                    </marquee>
                </div>
            </div>
            <div class="noticeboxr float-fr">
                <div class="current-time">{{currentTime}}</div>
            </div>
        </div>
    </div>
    <div class="header-menu wrap clearfix">
        <a routerLink="/home" routerLinkActive="active" class="logo">
            <img src="../assets/images/logo.png" alt="" />
        </a>
        <img class="logo-text" src="../assets/images/logo-text.png" alt="">
        <div class="nav nav-list">
            <a routerLink="/home" routerLinkActive="active" class="nav-item">首页</a>
            <a routerLink="/brick" routerLinkActive="active" class="nav-item">开奖记录</a>
            <a routerLink="/trend" routerLinkActive="active" class="nav-item">走势图</a>
        </div>
    </div>
</header>

<div class="main">
    <div class="wrap">
        <router-outlet></router-outlet>
    </div>
    <footer class="footer">
        <div class="footer-wrap wrap">
            <img src="../assets/images/logo-footer.png" alt="" class="footer-logo" />
            <div class="footer-text">
                <p class="tips">温馨提醒：本站所有数据均由团队收集整理，数据仅供参考之用，未满18周岁请不要购买彩票</p>
                <p class="copyright"> Copyright 2010-{{currentYear}} All Rights Reserved</p>
            </div>
        </div>
    </footer>
</div>